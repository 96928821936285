import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import './css/BoardGame.scss';

type BoardGameRowState = {

}

type BoardGameRowProps = {
  id: number,
  imgSrc: string,
  name: string,
  rulesLink: string,
  playersMin: number,
  playersMax: number,
  difficulty: string,
  length: number,
  gameStyle: string,
  age: number,
  notes: string,
  recentVotes: number
}

export default class BoardGameRow extends Component<BoardGameRowProps, BoardGameRowState>
{
  // constructor(props: BoardGameRowProps | Readonly<BoardGameRowProps>) {
  //   super(props);
  //   this.state = {
      
  //   }
  // }

  render() {
    return (
            <Row>
              <Col>Vote</Col>
              <Col><img src={this.props.imgSrc} alt="Board Game" /></Col>
              <Col>{this.props.name}</Col>
              <Col><a href={this.props.rulesLink} target="_new">Rules</a></Col>
              <Col>{this.props.playersMin} - {this.props.playersMax}</Col>
              <Col>{this.props.difficulty}</Col>
              <Col>{this.props.length}</Col>
              <Col>{this.props.gameStyle}</Col>
              <Col>{this.props.age}</Col>
              <Col>{this.props.notes}</Col>
              <Col>{this.props.recentVotes}</Col>
            </Row>
      );
  }

};
import React, {Component} from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';

export const importAll = (r: __WebpackModuleApi.RequireContext) =>
	r.keys().map((fileName: string) => ({
		slug: fileName.substr(2).replace(/\/img\/notes\/$/, ''),
		module: r(fileName)
}));

const images = importAll(require.context('./img/notes', true));

type NotesState = {
  imgIdx: number,
  currentNote: string,
  status: boolean|null,
  autoChange: boolean
}

type NotesProps = {

}

export default class Notes extends Component<NotesProps, NotesState>
{
  imgNames = ['Bass_A.jpg', 'Bass_A_2.jpg', 'Bass_B.jpg', 'Bass_C.jpg', 'Bass_D.jpg', 'Bass_D_2.jpg', 'Bass_E.jpg', 'Bass_E_2.jpg', 'Bass_F.jpg', 'Bass_F_2.jpg', 'Bass_G.jpg', 'Bass_G_2.jpg', 'Mid_B.jpg', 'Mid_B_2.jpg', 'Mid_C.jpg', 'Mid_C_2.jpg', 'Mid_D.jpg', 'Mid_D_2.jpg', 'Treble_A.jpg', 'Treble_A_2.jpg', 'Treble_B.jpg', 'Treble_B_2.jpg', 'Treble_C.jpg', 'Treble_D.jpg', 'Treble_E.jpg', 'Treble_E_2.jpg', 'Treble_F.jpg', 'Treble_F_2.jpg', 'Treble_G.jpg', 'Treble_G_2.jpg'];

  constructor(props: NotesProps | Readonly<NotesProps>) {
    super(props);
    this.state = {
      imgIdx: -1,
      currentNote: '',
      status: null,
      autoChange: true
    }
  }

  changeNote = () => {
    var tempImgIdx = Math.floor(Math.random() * this.imgNames.length);
    this.setState({
      imgIdx: tempImgIdx,
      currentNote: this.imgNames[tempImgIdx].split('_')[1].replace('.jpg', ''),
      status: null
    })
  }

  componentDidMount() {
    this.changeNote();
  }

  checkNote(guessedNote: string){
    if(guessedNote === this.state.currentNote){
      this.setState({
        status: true
      });
      if(this.state.autoChange){
        this.changeNote();
      }
    } else {
      this.setState({
        status: false
      });
    }
  }

  onCbChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      autoChange: e.target.checked
    })
  }

  render() {
    const greenStyle = {
      color: 'green'
    };
    const redStyle = {
      color: 'red'
    };

    return (
      <Container fluid>
        <Row>
          <Col><h2>Guess the Note</h2></Col>
        </Row>
        <Row>
          <Col><img src={images.find((img) => {
             return img.slug === this.imgNames[this.state.imgIdx];
          })?.module} alt='Note'/></Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Button onClick={() => this.checkNote('A')}>A</Button>&nbsp;&nbsp;&nbsp;
            <Button onClick={() => this.checkNote('B')}>B</Button>&nbsp;&nbsp;&nbsp;
            <Button onClick={() => this.checkNote('C')}>C</Button>&nbsp;&nbsp;&nbsp;
            <Button onClick={() => this.checkNote('D')}>D</Button>&nbsp;&nbsp;&nbsp;
            <Button onClick={() => this.checkNote('E')}>E</Button>&nbsp;&nbsp;&nbsp;
            <Button onClick={() => this.checkNote('F')}>F</Button>&nbsp;&nbsp;&nbsp;
            <Button onClick={() => this.checkNote('G')}>G</Button>&nbsp;&nbsp;&nbsp;
          </Col>
        </Row>
        <br />
        <Row>
          <Col><Button onClick={() => this.changeNote()}>Change Note</Button></Col>
        </Row>
        <br />
        <Row>
          <Col>
          {this.state.status === true ? <div style={greenStyle}>Correct</div> : ''}
          {this.state.status === false ? <div style={redStyle}>Try Again</div> : ''}
          </Col> 
        </Row>
        
        <br />
        <Row>
          <Col><input type="checkbox" checked={this.state.autoChange} onChange={(e) => this.onCbChange(e)} />&nbsp;&nbsp;Auto Change When Correct?</Col>
        </Row>
        
        {(window.location.href.indexOf("debug") !== -1) && <div>(Debug)<br />Note: {this.state.currentNote}</div>}
      </Container>
    )
  }
}


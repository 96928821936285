import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import BoardGameRow from './BoardGameRow';
import logo from './img/msbpro-logo.png';

function BoardGames()
{
  const tempBoardGameRow = <BoardGameRow id={1} imgSrc={logo} name="Test Game" age={10} difficulty="Easy"
  length={30} notes="Test Notes" playersMin={1} playersMax={3} recentVotes={0} rulesLink="http://www.google.com" gameStyle="Strategy" />;
  return (
    <Container>
      <h2>Board Game List</h2>
      <Container>
          <Row>
            <Col>&nbsp;</Col>
            <Col>&nbsp;</Col>
            <Col>Name</Col>
            <Col>Rules</Col>
            <Col>Players Min-Max</Col>
            <Col>Difficulty</Col>
            <Col>Length</Col>
            <Col>Style</Col>
            <Col>Age</Col>
            <Col>Notes</Col>
            <Col>Recent Votes</Col>
          </Row>
          {tempBoardGameRow}
      </Container>
    </Container>
  )
};

export default BoardGames;
import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

function Home()
{
  return (
    <Container fluid>
      <Row>
        <Col></Col>
      </Row>
    </Container>
  )
};

export default Home;
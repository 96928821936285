import React from 'react';
import Nav from './Nav';
import './css/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
      <div>
        <Nav></Nav>
      </div>
  );
}

export default App;

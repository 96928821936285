import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {HashLink} from 'react-router-hash-link';

import alienSceneFront from './img/3d/alienScene/front.png';
import alienSceneRight from './img/3d/alienScene/right.png';
import alienSceneBack from './img/3d/alienScene/back.png';
import alienSceneLeft from './img/3d/alienScene/left.png';
import alienSceneClose from './img/3d/alienScene/close.png';

import alienFace from './img/3d/alien/face.png';
import alienFront from './img/3d/alien/front.png';
import alienFrontRight from './img/3d/alien/frontRight.png';
import alienRight from './img/3d/alien/right.png';
import alienBack from './img/3d/alien/back.png';
import alienLeft from './img/3d/alien/left.png';
import alienFrontLeft from './img/3d/alien/frontLeft.png';
import alienTop from './img/3d/alien/top.png';

import wormFrontRight from './img/3d/worm/frontRight.png';
import wormFront from './img/3d/worm/front.png';
import wormFrontLeft from './img/3d/worm/frontLeft.png';
import wormLeft from './img/3d/worm/left.png';
import wormBack from './img/3d/worm/back.png';
import wormRight from './img/3d/worm/right.png';
import wormTop from './img/3d/worm/top.png';

const scrollWithOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

function ThreeD()
{
  return (
    <Container fluid>
        <Row>
            <Col>
                <h1>3d Models</h1>
                <p>All content, design, text and other materials contained within this website are copyrighted and owned by Mathew Boisvert. All rights reserved.</p>
                <p>NOTE: Any of the videos below will require quicktime to view (it gives the best quality based on the original output video).</p>
                
                <h3 id="top">Table of Contents</h3>
                <HashLink smooth to="#alienScene" scroll={el => scrollWithOffset(el)}>Alien Scene</HashLink><br />
                <HashLink smooth to="#alienSceneTable" scroll={el => scrollWithOffset(el)}>Alien Scene Turntable</HashLink><br />
                <HashLink smooth to="#alien" scroll={el => scrollWithOffset(el)}>Alien</HashLink><br />
                <HashLink smooth to="#alienTable" scroll={el => scrollWithOffset(el)}>Alien Turntable</HashLink><br />
                <HashLink smooth to="#worm" scroll={el => scrollWithOffset(el)}>Worm</HashLink><br />
                <HashLink smooth to="#wormTable" scroll={el => scrollWithOffset(el)}>Worm Turntable</HashLink>
                
                <h3 id ="alienScene">Alien Scene</h3>
                <p>This alien scene was my first ever model(s) in Zbrush based on a tutorial (it took me awhile to finish the tutorial). I did everything in the scene with the exception of the base tank shape (as I couldn't get it to be the same as the tutorial).</p>
                <img src={alienSceneFront} alt="alien front" />
                <br /><br />
                <img src={alienSceneRight} alt="alien right" />
                <br /><br />
                <img src={alienSceneBack} alt="alien back" />
                <br /><br />
                <img src={alienSceneLeft} alt="alien left" />
                <br /><br />
                <img src={alienSceneClose} alt="alien close" />
                <br /><br />
                <HashLink smooth to="#top" scroll={el => scrollWithOffset(el)}>Top</HashLink><br />
                <div id="alienSceneTable">
                    <video id="alienSceneTableVid" width="753" height="496" controls>
                        <source src="https://msbpro-site-public.s3.amazonaws.com/alienScene.mp4" type="video/mp4" />
                    </video>
                </div>
                <br />
                <HashLink smooth to="#top" scroll={el => scrollWithOffset(el)}>Top</HashLink><br />
                
                <h3 id="alien">Alien</h3>
                <p>This alien character was my 2nd model in Zbrush based on a tutorial.</p>
                <img src={alienFace} alt="alien face" />
                <br /><br />
                <img src={alienFront} alt="alien front" />
                <br /><br />
                <img src={alienFrontRight} alt="alien front right" />
                <br /><br />
                <img src={alienRight} alt="alien right" />
                <br /><br />
                <img src={alienBack} alt="alien back" />
                <br /><br />
                <img src={alienLeft} alt="alien left" />
                <br /><br />
                <img src={alienFrontLeft} alt="alien front left" />
                <br /><br />
                <img src={alienTop} alt="alien top" />
                <br /><br />
                <HashLink smooth to="#top" scroll={el => scrollWithOffset(el)}>Top</HashLink><br />
                <div id="alienTable">
                <video id="alienTableVid" width="753" height="496" controls>
                        <source src="https://msbpro-site-public.s3.amazonaws.com/alien.mp4" type="video/mp4" />
                    </video>
                </div>
                <br />
                <HashLink smooth to="#top" scroll={el => scrollWithOffset(el)}>Top</HashLink><br />
                <h3 id="worm">Worm</h3>
                <p>This worm character is my 3rd model based off a tutorial. It shows some examples of hair fibers and some type of slime fluid coming off his body and into the grate below. Had a lot of fun with the polypainting on this model. Hope you enjoy!</p>
                <img src={wormFrontRight} alt="worm front right" />
                <br /><br />
                <img src={wormFront} alt="worm front" />
                <br /><br />
                <img src={wormFrontLeft} alt="worm front front" />
                <br /><br />
                <img src={wormLeft} alt="worm left" />
                <br /><br />
                <img src={wormBack} alt="worm back" />
                <br /><br />
                <img src={wormRight} alt="worm right" />
                <br /><br />
                <img src={wormTop} alt="worm top" />
                <br /><br />
                <HashLink smooth to="#top" scroll={el => scrollWithOffset(el)}>Top</HashLink><br />
                <div id="wormTable">
                <video id="wormTableVid" width="753" height="496" controls>
                    <source src="https://msbpro-site-public.s3.amazonaws.com/worm.mp4" type="video/mp4" />
                </video>
                </div>
                <br />
                <HashLink smooth to="#top" scroll={el => scrollWithOffset(el)}>Top</HashLink><br />
                <p>All content, design, text and other materials contained within this website are copyright by Mathew Boisvert. All rights reserved.</p>
            </Col>
        </Row>
    </Container>
  )
};

export default ThreeD;
import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import {Switch, Route, Link} from "react-router-dom";
import logo from './img/msbpro-logo.png';
import Home from './Home';
import Notes from './Notes';
import BoardGames from './BoardGames';
import ThreeD from './ThreeD';

function App() {
  return (
    <div>
      <Navbar bg="dark" variant="dark" expand="lg" sticky="top" collapseOnSelect>
      <Navbar.Brand as={Link} to="/"><img className="Logo" src={logo} alt="logo" /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse>
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/" eventKey="1">Home</Nav.Link>
            <Nav.Link as={Link} to="/notes" eventKey="2">Notes Game</Nav.Link>
            {/* <Nav.Link as={Link} to="/boardgames" eventKey="3">Board Games</Nav.Link> */}
          </Nav>
      </Navbar.Collapse>
      </Navbar>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/notes">
          <Notes />
        </Route>
        <Route path="/boardgames">
          <BoardGames />
        </Route>
        <Route path="/3d">
          <ThreeD />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
}

function NotFound()
{
  return (
    <div>Not Found</div>
  );
}

export default App;